#services-page {

	.main-section {
		padding-top: 40px;
		padding-bottom: 40px;

		.col-titles {
			text-align: center;
		}

		.box-service-sample-1 {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.btn-fide {
			font-size: 1.35rem;
			font-family: "Raleway", sans-serif;
		}

		@media screen and (max-width: 767px) {
			.box-service-sample-1 {
				margin-top: 7px;
				margin-bottom: 7px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding-top: 25px;
  		padding-bottom: 25px;
		}
	}

}
