.btn-s1 {
  padding: 0.65rem 1.90rem 0.85rem 1.90rem;
  color: #ffffff !important;
  font-size: 1.45rem;
  font-weight: 600;
  letter-spacing: 2px;
  border-color: $csecondary;
  border-radius: 10px;
  background-color: $csecondary;

  &:hover, &:focus, &:active {
    border-color: $csecondary2 !important;
    background-color: $csecondary2 !important;
  }
}
