#header {
	position: relative;

	.header-content {
		position: relative;

		.navbar {
			background: $cprimary !important;

			.navbar-brand {
				img {
					max-height: 80px;
				}
			}

			.navbar-toggler {
				border: 1px solid #fff;

				.navbar-toggler-icon {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.99%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
				}
			}

			#nav-collapse {
				.navbar-nav {
					.simple-item {
						.nav-link {
							color: #ffffff;
							font-size: 1.45rem;
							@include transition(150ms);

							&:hover {
								color: $csecondary;
							}
						}
					}

					.dropdown-lang {
						.dropdown-toggle {
							color: #ffffff;
							font-size: 1.3rem;

							.lang {
								position: relative;
								top: 2px;

								.txt {
									@include transition(150ms);
								}

								.flag {
									position: relative;
									display: inline-block;
									margin-left: 2px;
									top: 2px;
									width: 35px;
									height: 20px;
									background-color: $cprimary2;
									background-position: center;
									background-repeat: no-repeat;
									background-size: cover;
									box-shadow: 0 0 1px rgba(255,255,255,0.0);
									@include transition(250ms);

									&.eng {
										background-image: url('../images/shared/lang-eng.svg');
									}
									&.esp {
										background-image: url('../images/shared/lang-esp.svg');
									}
								}
							}

							&:after {
								display: none !important;
							}

							&:hover {
								.lang {
									.txt {
										text-shadow: 0 0px 3px rgba(255,255,255,0.6);
									}

									.flag {
										box-shadow: 0 0 1px rgba(255,255,255,0.9);
									}
								}
							}
						}

						.dropdown-menu {
							min-width: auto;
							background-color: $cprimary2;

							li {
								.dropdown-item {
									color: #ffffff;
									cursor: pointer;
									padding: 0.25rem 1.2rem 0.25rem 1.05rem;

									span {
										display: inline-block;
										min-width: 32px;
										text-align: right;
									}

									.flag {
										position: relative;
										display: inline-block;
										margin-left: 3px;
										top: 2px;
										width: 28px;
										height: 16px;
										background-color: $cprimary;
										background-position: center;
										background-repeat: no-repeat;
										background-size: cover;

										&.eng {
											background-image: url('../images/shared/lang-eng.svg');
										}
										&.esp {
											background-image: url('../images/shared/lang-esp.svg');
										}
									}

									&:hover {
										background-color: $cprimary;
									}
								}
							}
						}

						&.show {
							.dropdown-toggle {
								.lang {
									.txt {
										text-shadow: 0 0px 3px rgba(255,255,255,0.6);
									}

									.flag {
										box-shadow: 0 0 1px rgba(255,255,255,0.9);
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				#nav-collapse {
					.navbar-nav {
						.simple-item {
							margin-left: 1.7vw;
							margin-right: 1.7vw;
						}
					}
				}
			}

			@media screen and (max-width: 1099px) {
				.navbar {
					.navbar-brand {
						img {
							max-height: 62px;
						}
					}

					#nav-collapse {
						.navbar-nav {
							.simple-item {
								margin-left: 1.2vw;
								margin-right: 1.2vw;

								.nav-link {
									font-size: 1.3rem;
								}
							}

							.dropdown-lang {
								.dropdown-toggle {
									font-size: 1.2rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar {
				.navbar-brand {
					img {
						max-height: 40px;
					}
				}

				#nav-collapse {
					.navbar-nav {
						.simple-item {
							.nav-link {
								padding-top: 0.2rem;
								padding-bottom: 0.2rem;
								font-size: 1.25rem;
							}
						}

						.dropdown-lang {
							.dropdown-toggle {
								font-size: 1.20rem;

								.lang {
									.flag {
										top: 3px;
									}
								}
							}

							.dropdown-menu {
								li {
									.dropdown-item {
										&:hover {
											background-color: transparent !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
