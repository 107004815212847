.box-service-sample-1 {
  position: relative;
  font-family: "Raleway", sans-serif;

  .box-service {
    position: relative;
    display: inline-block;
    border-radius: 8px;
    width: 100%;
    text-decoration: none !important;
    overflow: hidden;
    box-shadow: 0 0 3px rgba(16,24,32,0.0);
    background-color: $cprimary;
    @include transition(150ms);

    .box-image {
      display: block;
      height: 320px;
      background-color: $cprimary2;
      @include transition(150ms);
    }

    .box-text {
      position: relative;
      display: block;
      padding: 20px 22px;
      color: #ffffff;

      &:after {
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 65px;
        background: rgb(16,24,32);
        background: linear-gradient(0deg, rgba(16,24,32,1) 40%, rgba(16,24,32,0) 100%);
        @include transition(150ms);
      }

      .name {
        margin-bottom: 5px;
        min-height: 66px;
        font-size: 1.15rem;
        letter-spacing: 2px;
        text-align: center;
        text-transform: uppercase;
      }

      .short-descr {
        height: 162px;
        font-size: 0.9rem;
        line-height: 1.4;
        text-align: justify;
      }
    }

    &:hover {
      box-shadow: 0 0 3px rgba(16,24,32,0.6);
      background-color: $cprimary2;

      .box-image {
        opacity: 0.90;
      }

      .box-text {
        &:after {
          background: linear-gradient(0deg, rgba(7,11,15,1) 40%, rgba(7,11,15,0) 100%);
        }
      }
    }
  }

  @media screen and (max-width: 1499px) {
    .box-service {
      .box-text {
        .name {
          min-height: 62px;
          font-size: 1.08rem;
        }

        .short-descr {
          height: 130px;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .box-service {
      .box-text {
        .name {
          min-height: 58px;
          font-size: 1.00rem;
        }

        .short-descr {
          font-size: 0.85rem;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .box-service {
      .box-text {
        .short-descr {
          height: 100px;
        }
      }
    }
  }
}
