#service-detail-page {
  position: relative;
  min-height: 69vh;

  .banner-section {
    position: relative;
    height: 220px;
    background-color: $soft-gray;
  }

  .main-section {
    position: relative;
    padding: 25px 0;

    .box-title {
      margin-bottom: 15px;
      display: block;
      text-align: center;

      .s-title {
        color: $cprimary;
        font-size: 2.05rem;
        font-weight: 700;
      }
    }

    .box-content {
      display: block;
      font-family: "Raleway", sans-serif;
      text-align: justify;

      ol, ul {
        padding-left: 15px;
      }

      p {
        margin-bottom: 20px;
      }

      .btn-contact {
        display: block;
        padding: 9px 15px;
        color: #ffffff;
        cursor: pointer;
        font-family: "Raleway", sans-serif;
        font-size: 1.40rem;
        font-weight: 600;
        letter-spacing: 8px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none !important;
        border: 2px solid $cprimary;
        border-radius: 8px;
        background-color: $cprimary;

        &:hover {
          background-color: $cprimary2;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .box-title {
        .s-title {
          font-size: 1.85rem;
        }
      }

      .box-content {
        font-size: 0.95rem;
      }
    }
  }
}
