.contact-section {
  position: relative;
  padding: 140px 0;
  color: #ffffff;
  background-color: $cprimary;

  &:before {
    content: " ";
    position: absolute;
    top: 60px;
    left: 0;
    width: 36vw;
    height: calc(100% - 60px);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../images/pages/contact/img-contact.png');
  }

  .col-form {
    margin-top: 15px;
    margin-bottom: 15px;

    .box-titles {
      text-align: right;

      .title-s1 {
        margin-bottom: 10px;
      }

      .txt-email {
        margin-top: 15px;
        text-align: left;

        span {
          position: relative;
          font-family: "Raleway", sans-serif;
          font-size: 1.9rem;
          font-weight: 300;

          &:after {
            content: " ";
            position: absolute;
            margin-left: 22px;
            top: 50%;
            width: 112px;
            border-top: 2px solid $csecondary;
          }
        }
      }
    }

    .box-form {
      margin-top: 22px;

      .custom-f-group-s1 {
        label, legend {
          color: #6f8087;
          font-size: 1.3rem;
          letter-spacing: 1px;
        }

        >div {
          .form-control {
            padding-left: 24px;
            font-family: "Raleway", sans-serif;
            border: 0;
            border-radius: 9px;
          }

          .form-control-lg {
            height: 52px;
          }

          textarea.form-control-lg {
            padding-top: 0.75rem;
            height: auto !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .col-form {
      .box-titles {
        .txt-email {
          span {
            &:after {
              width: 7vw;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    padding: 80px 0;

    &:before {
      width: 100%;
      opacity: 0.8;
    }

    .col-form {
      .box-titles {
        text-align: center;

        .txt-email {
          text-align: center;

          span {
            display: inline-block;
            padding-bottom: 14px;

            &:after {
              top: inherit;
              bottom: 0;
              left: calc(50% - 35px);
              margin-left: 0;
              width: 70px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &:before {
      opacity: 0.5;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 32px 0;

    &:after {
      opacity: 0.35;
    }

    .col-form {
      .box-titles {
        .txt-email {
          span {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
