.title-s1 {
  color: $csecondary;
  font-family: "Raleway", sans-serif;
  font-size: 2.05rem;
  font-weight: 300;
  letter-spacing: 8px;

  @media screen and (max-width: 1399px) {
    font-size: 1.95rem;
  }
  @media screen and (max-width: 991px) {
    font-size: 1.80rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 1.6rem;
  }
}

.title-s2 {
  font-size: 3.8rem;
  font-weight: 600;
  letter-spacing: 5px;
  line-height: 1.0;

  @media screen and (max-width: 1399px) {
    font-size: 3.50rem;
  }
  @media screen and (max-width: 991px) {
    font-size: 3.0rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 2.7rem;
  }
}

.title-s3 {
  color: $csecondary;
  font-family: "Raleway", sans-serif;
  font-size: 1.99rem;
  font-weight: 500;
  text-align: center;
}
