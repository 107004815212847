#faqs-page {

  .faqs-section {
		position: relative;
		padding: 45px 0;

		.title-s2 {
			margin-bottom: 36px;
			text-align: center;
		}

		.faqs-content {
			.accordion {
				.card {
					font-family: "Raleway", sans-serif;
					border: 0;
					border-top: 2px solid $csecondary;
					border-radius: 0;

					.card-header {
						padding: 0 !important;
						border: 0;
						background: transparent !important;

						a {
							position: relative;
							display: block;
							padding: 20px 30px 18px 0;
							color: #101820;
							cursor: pointer;
							font-size: 1.7rem;
							font-weight: 300;
							line-height: 1.1;
							text-decoration: none !important;
							@include transition(150ms);

							&:after {
								position: absolute;
								top: 50%;
								right: 0;
								margin-top: -13px;
								color: $csecondary;
								font-family: "Font Awesome 5 Pro";
								font-size: 1.8rem;
								font-weight: 300;
								line-height: 1;
							}

							&.not-collapsed {
								&:after {
									content: "\f068";
								}
							}
							&.collapsed {
								&:after {
									content: "\f067";
								}
							}

							&:hover {
								color: $csecondary;
							}
						}
					}

					.cll-content {
						.card-body {
							padding-top: 4px;
							padding-left: 0;
							padding-right: 0;
							color: #6a7b82;
							font-size: 1.15rem;
              text-align: justify;

              h5, h6 {
                font-weight: 700;
              }
              h5 {
                // font-size: 1.35rem;
              }

							ul, ol {
								padding-left: 17px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1399px) {
			.faqs-content {
				.accordion {
					.card {
						.card-header {
							a {
								font-size: 1.55rem;
							}
						}

						.cll-content {
							.card-body {
								font-size: 1.05rem;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.title-s2 {
				margin-bottom: 25px;
			}

			.faqs-content {
				.accordion {
					.card {
						.card-header {
							a {
								padding: 20px 24px 18px 0;
								font-size: 1.25rem;

								&:after {
									margin-top: -11px;
									font-size: 1.5rem;
								}
							}
						}

						.cll-content {
							.card-body {
								padding-top: 0;
								font-size: 1.00rem;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 767px) {
			.faqs-content {
				.accordion {
					.card {
						.card-header {
							a {
								padding: 14px 20px 14px 0;
								font-size: 1.05rem;
								font-weight: 500;

								&:after {
									margin-top: -8px;
									font-size: 1.2rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			.faqs-content {
				.accordion {
					.card {
						.card-header {
							a {
								padding: 12px 18px 12px 0;
								font-size: 0.95rem;
								font-weight: 600;

								&:after {
									margin-top: -6px;
									font-size: 1.00rem;
								}
							}
						}

						.cll-content {
							.card-body {
								font-size: 0.85rem;
							}
						}
					}
				}
			}
		}
	}

}
