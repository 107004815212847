body,html{
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	color: #101820;
}

p,h1,h2,h3,h4,h5,h6{
	margin-bottom: 0px;
}

// Container
.oversized-container{
	@media screen and (min-width: 992px){
		max-width: 98%;
	}
	@media screen and (min-width: 1670px){
		max-width: 1620px;
	}
}

// Backgrounds
.placed-backg{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Remove outline
select:focus, select:active, select:hover, button:focus, button:active, button:hover,
input:focus, input:active, input:hover, textarea:focus, textarea:active, textarea:hover{
  box-shadow: none !important;
  outline: none !important;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}

// Remove Arrows/Spinners
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

// WhatsApp link
.ic-glob-whatsapp{
	@extend .flex-center-xy;
	position: fixed;
	padding-bottom: 2px;
	bottom: 20px;
	right: 20px;
	width: 60px;
	height: 60px;
	color: #ffffff !important;
	text-align: center;
	font-size: 32px;
	background-color: #25d366;
	border-radius: 50%;
	cursor: pointer;
	z-index: 88;

	&:hover{
		text-decoration: none;
	}
}
