#home-page {

	.banners-section {
		position: relative;

		.swiper {
			.swiper-slide {
				.box-img {
					display: block;
					background-color: #ffffff;

					img {
						width: 100%;
					}
				}
			}
		}

		.swiper-desktop {}
		.swiper-mobile {
			display: none;
		}

		@media screen and (max-width: 991px) {
			.swiper-desktop {
				display: none;
			}
			.swiper-mobile {
				display: block;
			}
		}
	}

	.about-section {
		position: relative;
		padding-top: 233px;
		padding-bottom: 229px;
		margin-top: -233px;
		z-index: 30;

		&:before {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 233px;
			background-position: top center;
			background-repeat: no-repeat;
			background-image: url('../images/pages/home/curve-1.svg');
		}

		&:after {
			content: " ";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 229px;
			background-position: bottom center;
			background-repeat: no-repeat;
			background-image: url('../images/pages/home/curve-2.svg');
		}

		.box-content {
			position: relative;
			display: block;
			padding: 155px 0px 85px 0px;
			color: #ffffff;
			background-color: $cprimary;

			.col-titles {
				margin-top: -5%;

				.title-s1 {
					margin-bottom: 10px;
				}

				.title-s2 {
					.logo {
						position: relative;
						top: 6px;
						max-height: 70px;
					  margin-left: 5px;

						@media screen and (min-width: 1500px) {
							position: relative;
							top: -8px;
						}
					}
				}
			}

			.col-info {
				font-family: "Raleway", sans-serif;
				font-size: 1.80rem;
				font-weight: 300;
				text-align: justify;
			}
		}

		@media screen and (max-width: 1499px) {
			padding-top: 177px;
			padding-bottom: 180px;

			&:before {
				height: 178px;
				background-size: cover;
			}
			&:after {
				height: 180px;
				background-size: cover;
			}

			.box-content {
				padding: 155px 0px 105px 0px;

				.col-info {
					font-size: 1.65rem;
				}
			}
		}
		@media screen and (max-width: 1199px) {
			padding-top: 130px;
			padding-bottom: 141px;

			&:before {
				height: 130px;
				background-size: cover;
			}
			&:after {
				height: 142px;
				background-size: cover;
			}

			.box-content {
				padding: 95px 0px 65px 0px;

				.col-info {
					font-size: 1.40rem;
				}
			}
		}
		@media screen and (max-width: 991px) {
			padding-top: 117px;
			padding-bottom: 115px;

			&:before {
				height: 117px;
				background-size: cover;
			}
			&:after {
				height: 115px;
				background-size: cover;
			}

			.box-content {
				padding: 55px 0px 55px 0px;

				.col-titles {
					margin-top: 0;
					margin-bottom: 22px;

					.title-s2 {
						.logo {
							top: 5px;
							max-height: 65px;
						}
					}
				}

				.col-info {
					font-size: 1.40rem;
				}
			}
		}
		@media screen and (max-width: 767px) {
			padding-top: 78px;
			padding-bottom: 96px;

			&:before {
				height: 78px;
			}
			&:after {
				height: 96px;
			}

			.box-content {
				.col-titles {
					text-align: center;
				}

				.col-info {
					text-align: center;
					font-size: 1.25rem;
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding-top: 53px;
			padding-bottom: 84px;

			&:before {
				height: 53px;
			}
			&:after {
				height: 84px;
			}

			.box-content {
				.col-titles {
					.title-s2 {
						.logo {
							margin-left: 0;
							max-width: 95%;
						}
					}
				}

				.col-info {
					font-size: 1.05rem;
				}
			}
		}
	}

	.services-section {
		position: relative;
		padding: 34px 0 44px 0;

		.title-s1, .title-s2 {
			text-align: center;
		}

		.carousel-container {
			margin-top: 35px;

			.box-carousel {
				.swiper {
					.swiper-slide {
				    width: 80%;
						padding: 20px 20px 60px 20px;

						.box-service {
							pointer-events: none;
						}
				  }

					.swiper-slide-active {
						.box-service {
							pointer-events: all !important;
							@include transition(150ms);
							@include cp-property(transform, scale(1.035));
						}
					}

					.swiper-button-prev, .swiper-button-next {
						margin-top: -32px;
						width: 64px;
						height: 64px;
						color: #ffffff;
						border-radius: 12px;
						background-color: $csecondary;
						@include transition(250ms);

						&:after {
							font-size: 1.7rem;
  						font-weight: 600;
						}

						&:hover {
							background-color: $csecondary2;
						}
					}

					.swiper-button-prev {
						padding-right: 6px;
					}
					.swiper-button-next {
						padding-left: 2px;
					}

					.swiper-pagination {
						bottom: 0;

						.swiper-pagination-bullet {
							margin: 0 6px;
							width: 22px;
							height: 22px;
							opacity: 1;
							background-color: #b2b9c0;
						}
						.swiper-pagination-bullet-active {
							background-color: $csecondary;
						}
					}
				}

				.box-service {
					position: relative;
					display: flex;
					flex-wrap: wrap;
					flex: 0 0 100%;
					max-width: 100%;
					color: #ffffff;
					border-radius: 12px;
					text-decoration: none !important;
					background-color: $cprimary;
					overflow: hidden;
					@include transition(150ms);

					&:hover {
						opacity: 0.95;
					}

					.col-image {
						flex: 0 0 432px;
						max-width: 432px;
						background-color: $cprimary2;
					}

					.col-text {
						padding: 35px 42px;
						height: 600px;
						display: flex;
						align-items: center;
						font-family: "Raleway", sans-serif;

						.content {
							position: relative;
							display: inline-block;
							width: 100%;
							max-height: 100%;
							overflow: auto;
							overflow-x: hidden;

							.title {
								margin-bottom: 22px;
								font-size: 1.8rem;
								font-weight: 300;
								letter-spacing: 2px;
								line-height: 1.3;
								text-transform: uppercase;
							}

							.descr {
								padding-right: 8px;
								text-align: justify;
								font-size: 1.25rem;

								ol, ul {
									padding-left: 15px;
								}

								p {
									margin-bottom: 15px;

									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
					}
				}
			}
		}

		.btn-fide {
			font-size: 1.30rem;
			font-family: "Raleway", sans-serif;
		}

		@media screen and (max-width: 1699px) {
			.carousel-container {
				.box-carousel {
					.box-service {
						.col-image {
							flex: 0 0 420px;
							max-width: 420px;
						}

						.col-text {
							.content {
								.title {
									margin-bottom: 14px;
									font-size: 1.65rem;
								}

								.descr {
									font-size: 1.10rem;
									line-height: 1.6;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 1599px) {
			.carousel-container {
				.box-carousel {
					.box-service {
						.col-image {
							flex: 0 0 390px;
							max-width: 390px;
						}

						.col-text {
							.content {
								.title {
									margin-bottom: 10px;
									font-size: 1.45rem;
								}

								.descr {
									font-size: 1.0rem;
									line-height: 1.5;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 1399px) {
			.carousel-container {
				.box-carousel {
					.swiper {
						.swiper-slide {
					    width: 90%;
					  }
					}

					.box-service {
						.col-text {
							padding: 28px 28px 28px 35px;
							height: 550px;

							.content {
								.title {
									margin-bottom: 10px;
									font-size: 1.30rem;
								}

								.descr {
									font-size: 0.95rem;
									line-height: 1.5;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 1199px) {
			padding-bottom: 35px;

			.carousel-container {
				.box-carousel {
					.box-service {
						.col-image {
							flex: 0 0 260px;
    					max-width: 260px;
						}

						.col-text {
							padding: 15px 15px 15px 24px;
							height: 460px;

							.content {
								.title {
									margin-bottom: 4px;
									font-size: 1.00rem;
								}

								.descr {
									font-size: 0.80rem;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.carousel-container {
				margin-top: 24px;

				.box-carousel {
					.swiper {
						.swiper-slide {
							padding: 0 0 45px 0;
					  }

						.swiper-button-prev, .swiper-button-next {
							margin-top: -21px;
							width: 42px;
  						height: 42px;
						}
						.swiper-button-prev {
							left: 0;
							border-radius: 0 5px 5px 0;
						}
						.swiper-button-next {
							right: 0;
							border-radius: 5px 0 0 5px;
						}

						.swiper-slide-active {
							.box-service {
								@include cp-property(transform, scale(1.00));
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 767px) {
			.carousel-container {
				.box-carousel {
					.swiper {
						.swiper-button-prev, .swiper-button-next {
							margin-top: -40px;
							width: 22px;
						  height: 54px;

							&:after {
								font-size: 1.0rem;
							}
						}
					}

					.box-service {
						.col-image {
							flex: 0 0 200px;
							max-width: 200px;
						}
					}
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding-bottom: 28px;

			.carousel-container {
				.box-carousel {
					.box-service {
						.col-image {
							display: none !important;
						}
					}
				}
			}
		}
	}

}
