#text-page{
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  color: #2a2b2d;
  font-family: "Raleway", sans-serif;
  font-size: 0.95rem;
  line-height: 1.35;
  text-align: justify;
  min-height: 75vh;

  h1{
    color: $csecondary;
    font-size: 1.99rem;
    font-weight: 500;
    text-align: center;
  }

  h5{
    font-size: 1.30rem;
  }
  h2,h3,h4,h5,h6 {
    font-weight: 700;
  }

  ul,ol{
    padding-left: 17px;
    margin-bottom: 0;
  }

  strong{
    font-weight: 600;
  }
}
