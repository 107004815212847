#footer {
  position: relative;
  padding: 40px 0 34px 0;
  color: #ffffff;
  background-color: $cprimary;

  a {
    color: #ffffff;
  }

  .container {
    >.row {
      align-items: center;
    }
  }

  .col-footer {
    text-align: center;

    a {
      font-size: 1.45rem;
    }
  }

  .col-logo {
    margin-bottom: 10px;
    text-align: center;

    a {
      display: inline-block;

      img {
        width: 350px;
        max-width: 90%;
      }
    }

    .who-title {
      width: 440px;
      max-width: 100%;
      margin: 0 auto 0 auto;
      color: #ffffff;
      font-family: "Raleway", sans-serif;
      font-size: 1.75rem;
      font-weight: 600;
      text-align: center;
    }

    .regulators {
      margin: 6px auto 0 auto;
      width: 870px;
      max-width: 100%;
      text-align: center;

      img {
        max-width: 100%;
      }

      .mb {
        display: none;
      }
    }
  }

  .col-line {
    position: relative;
    margin-top: 34px;
    margin-bottom: 32px;

    &:after {
      content: " ";
      position: absolute;
      left: 15px;
      width: calc(100% - 30px);
      border-top: 1px solid #773c32;
    }
  }

  .col-extra {
    margin-bottom: 16px;
    font-family: "Raleway", sans-serif;
    text-align: center;

    .title {
      margin-bottom: 14px;
      font-size: 1.15rem;
      font-weight: 700;
    }

    .col-ar {
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 0.85rem;
    }
  }

  .col-copyright {
    text-align: center;

    h5 {
      font-family: "Raleway", sans-serif;
      font-size: 1.15rem;
      font-weight: 300;
      letter-spacing: 1px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 32px 0 20px 0;

    .container {
      >.row {
        align-items: unset;
      }
    }

    .col-logo {
      margin-bottom: 12px;
      text-align: center;

      .who-title, .regulators {
        width: 100%;
      }

      .who-title {
        font-size: 1.35rem;
      }
      .regulators {
        margin-top: 5px;
      }
    }

    .col-footer {
      a {
        font-size: 1.20rem;
      }
    }

    .col-line {
      margin-top: 22px;
      margin-bottom: 20px;
    }

    .col-copyright {
      h5 {
        font-size: 1.00rem;
      }
    }
  }
  @media screen and (max-width: 575px) {
    padding: 28px 0 20px 0;

    .col-logo {
      .regulators {
        .dk {
          display: none;
        }
        
        .mb {
          display: inline-block;
        }
      }
    }

    .col-extra {
      .title {
        font-size: 1.00rem;
      }

      .col-ar {
        line-height: 1.15;
        font-size: 0.80rem;
      }
    }

    .col-copyright {
      h5 {
        font-size: 0.90rem;
      }
    }
  }
}
