#about-us-page {

  .main-section {
		position: relative;
		padding-bottom: 229px;
		z-index: 30;

		&:after {
			content: " ";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 229px;
			background-position: bottom center;
			background-repeat: no-repeat;
			background-image: url('../images/pages/home/curve-2.svg');
		}

		.box-content {
			position: relative;
			display: block;
			padding: 155px 0px 20px 0px;
			color: #ffffff;
			background-color: $cprimary;

			.col-titles {
				margin-top: -5%;

				.title-s1 {
					margin-bottom: 10px;
				}

				.title-s2 {
					.logo {
						position: relative;
						top: -8px;
						max-height: 63px;
					  margin-left: 5px;
					}
				}
			}

			.col-info {
				font-family: "Raleway", sans-serif;
				font-size: 1.80rem;
				font-weight: 300;
        text-align: justify;
			}
		}

		@media screen and (max-width: 1499px) {
			padding-bottom: 180px;

			&:after {
				height: 180px;
				background-size: cover;
			}

			.box-content {
				padding: 155px 0px 15px 0px;

				.col-titles {
					.title-s2 {
						.logo {
							max-height: 60px;
						}
					}
				}

				.col-info {
					font-size: 1.65rem;
				}
			}
		}
		@media screen and (max-width: 1199px) {
			padding-bottom: 141px;

			&:after {
				height: 142px;
				background-size: cover;
			}

			.box-content {
				padding: 95px 0px 65px 0px;

				.col-info {
					font-size: 1.40rem;
				}
			}
		}
		@media screen and (max-width: 991px) {
			padding-bottom: 115px;

			&:after {
				height: 115px;
				background-size: cover;
			}

			.box-content {
				padding: 55px 0px 10px 0px;

				.col-titles {
					margin-top: 0;
					margin-bottom: 22px;

					.title-s2 {
						.logo {
              top: 5px;
							max-height: 65px;
						}
					}
				}

				.col-info {
					font-size: 1.40rem;
				}
			}
		}
		@media screen and (max-width: 767px) {
			padding-bottom: 96px;

			&:after {
				height: 96px;
			}

			.box-content {
				.col-titles {
					text-align: center;
				}

				.col-info {
					text-align: center;
					font-size: 1.25rem;
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding-bottom: 84px;

			&:after {
				height: 84px;
			}

			.box-content {
				.col-titles {
					.title-s2 {
						.logo {
							margin-left: 0;
							max-width: 95%;
						}
					}
				}

				.col-info {
					font-size: 1.05rem;
				}
			}
		}
	}

  .info-section {
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: center;

    .title-1 {
      font-size: 2.3rem;
      font-weight: 700;
    }

    .p-lg {
      font-size: 1.40rem;
    }
  }

}
